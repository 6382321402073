// @ts-nocheck

import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Logo from "../components/Logo";
import ActionButton from "../components/ActionButton";
import { Input } from "@mui/material";
import logo from "../assets/icons/verify-logo.svg";
import authService from "../services/authService";
import { LoaderCircle } from "lucide-react";
function OtpVerfiy() {
  const type = process.env.REACT_APP_TYPE;
  const IP = process.env.REACT_APP_IP;
  const REACT_APP_PROD_SSL_SWITCH = process.env.REACT_APP_PROD_SSL_SWITCH;
const REACT_APP_PROD_SSL_PORT = process.env.REACT_APP_PROD_SSL_PORT;
  const BACKEND_URL =
    type == "dev" ? "http://localhost:5000" : `${REACT_APP_PROD_SSL_SWITCH}://${IP}:${REACT_APP_PROD_SSL_PORT}`;
  const [orginemail, setEmail] = useState("");
  const navigate = useNavigate();
  const [isloading, setisloading] = useState(false);
  const [sent, setsent] = useState(false);
  const [otp, setotp] = useState("");
  const { mail, name, password, gender } = useParams();
  useEffect(() => {
    console.log(password, "name");
    setEmail(decodeURIComponent(mail));
  }, []);
  const sendOtp = async () => {
    console.log(decodeURIComponent(mail));
    setisloading(true);
    try {
      const res = axios.post(`${BACKEND_URL}/v1/auth/send-otp?email=${mail}`);
      console.log(res, "res");
      res.then((data) => {
        toast.success("Otp send to your mail successfully!");
        setisloading(false);
        setsent(true);
      });
    } catch (err) {
      toast.error(err);
      setisloading(false);
    }
  };
  const resendOtp = async () => {
    console.log(decodeURIComponent(mail));

    try {
      const res = axios.post(`${BACKEND_URL}/v1/auth/send-otp?email=${mail}`);
      console.log(res, "res");
      res.then((data) => {
        toast.success("otp has been send again !");
      });
    } catch (err) {
      toast.error(err);
    }
  };
  const verifyOtp = async () => {
    setisloading(true);
    try {
      // Send the OTP verification request
      const res = await axios.post(
        `${BACKEND_URL}/v1/auth/verify-otp?email=${mail}`,
        { otp: otp }
      );

      console.log(res.data, "res");

      // If OTP is verified successfully, proceed with registration
      try {
        const register = await authService.register({
          name: name,
          email: mail,
          password: password,
          gender: gender,
        });
        console.log(register, "register_res");
        // data: { createdUser, accessToken },

        localStorage.setItem("accessToken", register?.data?.accessToken);

        toast.success("Registered Successfully!");
        setsent(true);

        window.location.href="/";
      } catch (registerError) {
        console.error(registerError);
        toast.error(
          registerError.response?.data?.message || "Registration failed."
        );
      }
    } catch (err) {
      console.error(err);
      toast.error(
        err.response?.data?.message ||
          "OTP verification failed. Please try again."
      );
    } finally {
      setisloading(false);
    }
  };

  return (
    <div>
      <div className="flex flex-col  items-center   min-h-screen">
        <div>
          <img src={logo} className="w-[400px] mt-10" />
          {!sent ? (
            <div className="bg-white p-2 mt-5 rounded-2xl">
              <div className="text-3xl font-semibold  text-center">
                OTP Verification{" "}
              </div>
              <div className="text-black mt-10 text-2xl text-center ">
                {" "}
                Otp will be send to {orginemail}
              </div>
              <div>
                <div className="mt-auto flex flex-col gap-y-3 w-full px-6 py-8">
                  {!isloading ? (
                    <ActionButton
                      buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md"
                      buttonText="Send Otp"
                      onClick={sendOtp}
                    />
                  ) : (
                    <div className="bg-gradient-to-br p-4 flex justify-between from-purple to-lightPurple rounded-md">
                      {" "}
                      <LoaderCircle className="animate-spin text-white" />
                      <div className="text-white">Sending your Otp </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="bg-white p-2 mt-20 rounded-2xl">
              <div>
                <div className="mt-auto flex flex-col gap-y-3 w-full px-6 py-8">
                  <div>
                    {" "}
                    <input
                      placeholder="enter your otp"
                      className="bg-[#F2F6FA] border border-gray-400 rounded-xl p-4 w-full"
                      onChange={(e) => setotp(e.target.value)}
                    />{" "}
                  </div>
                  <div
                    onClick={resendOtp}
                    className="text-[#42EBAB] cursor-pointer font-semibold text-right"
                  >
                    {" "}
                    Resend{" "}
                  </div>
                  {!isloading ? (
                    <ActionButton
                      buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md"
                      buttonText="Verify Otp"
                      onClick={verifyOtp}
                    />
                  ) : (
                    <div className="bg-gradient-to-br p-4 flex justify-between from-purple to-lightPurple rounded-md">
                      {" "}
                      <LoaderCircle className="animate-spin text-white" />
                      <div className="text-white">Verifying your Otp </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default OtpVerfiy;

import React, { useState, useCallback, useMemo, useEffect } from "react";
import toast from "react-hot-toast";
// import { userPostApis} from "../services/forumService";
import { api } from "../config/apiConfig";
import { useLocation } from "react-router-dom";
import Ably from "ably";

const ably = new Ably.Realtime(
  "vM7HIw.CGET9A:EdKyVx9CIqcaYxHTOjR79eQk31iqsY55LInmxVUX9v4"
);

export const useChat = () => {
  const [chats, setChats] = useState<any>([]);

  const [messages, setMessages] = useState<any>([]);
  const [msg, setMsg] = useState<any>("");
  const [currChat, setCurrChat] = useState<any>();

  const { pathname,search } = useLocation();

  const getMessages = useCallback(async () => {
    if (pathname.includes("chat") && pathname.split("/").length > 2) {
      const id = pathname.split("/")[2];
      const chatId=search.split("=")[1];

      try {
        const updateUnreadMessage: any = await api.put(`/chat/update-status/${chatId}`, {
          userIds: [id],
        });
        const getMessages: any = await api.post("/chat/create-chat", {
          userIds: [id],
        });
        // console.log("Chat messages", getMessages);
        setMessages(
          getMessages.data.messages.map((msg: any) => {
            return {
              message: msg.content,
              sender: msg.sender.email,
              time: msg.timestamp,
              type:msg.type
            };
          })
        );
        setCurrChat(getMessages.data);
      } catch (e) {
        console.log(e);
        toast.error("Something went wrong!");
      }
    }
  }, []);

  useEffect(() => {
    getMessages();
  }, [getMessages]);

  const getChats = useCallback(async () => {
    try {
      const getChats: any = await api.get("/chat/get-chats");
      // console.log("Chat list", getChats.data);

      setChats(getChats.data);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  }, []);

  useEffect(() => {
    getChats();
  }, [getChats]);

  const getRooms = useCallback(async () => {
    try {
      const getRooms: any = await api.get("/chat/get-rooms");
      // console.log("Room list", getRooms.data);

      // setChats(getChats.data);
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  }, []);

  useEffect(() => {
    getRooms();
  }, [getRooms]);

  useEffect(() => {
    if (!currChat) return;

    const channel = ably.channels.get(`chat-${currChat.id}`);

    const handleUpdate = (data: any) => {
      // console.log("Data received by ably", data.data);
      if (data.data.chatId === currChat.id) {
        setMessages((prevMessages: any) => [
          ...prevMessages,
          {
            message: data.data.content,
            sender: data.data.sender.email,
            time: data.data.timestamp,
            type: data.data.type,
            image:msg.sender?.profilePic || ''
          },
        ]);
      }
    };

    channel.subscribe("message", handleUpdate);

    return () => {
      channel.unsubscribe("message", handleUpdate);
    };
  }, [currChat]);
  const handleFileUpload = async (file: any) => {
    if (file) {
      try {
        const id = pathname.split("/")[2];

        const getMessages: any = await api.post("/chat/create-chat", {
          userIds: [id],
        });
        // console.log("Chat messages", getMessages);
        if (getMessages.data) {
          // console.log("the send message url", file);
          await api.post("/chat/create-message", {
            fileUrl: file,
            chatId: getMessages.data.id,
            type: "link",
            
          });
          setMsg("");
        }
      } catch (e) {
        console.log(e);
        toast.error("Something went wrong!");
      }
    } else {
      console.log("Current chat is not defined");
    }
  };
  // useEffect(() => {
  //   // console.log("Current chat state:", currChat);
  // }, [currChat]);
  const deleteChat=async(chatId:any)=>{
    try {
      await api.delete(`/chat/delete-chat/${chatId}`);
      toast.success("Chat deleted successfully");
      window.location.reload();
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  }
  const handleChat = async (e: any) => {
    e.preventDefault();
    try {
      // const newChat = {
      //   msg,
      //   time: new Date().toLocaleTimeString("en-US", {
      //     hour: "2-digit",
      //     minute: "2-digit",
      //   }),
      //   isMe: true,
      // };

      await api.post("/chat/create-message", {
        content: msg,
        chatId: currChat.id,

      });
      setMsg("");
    } catch (e) {
      console.log(e);
      toast.error("Something went wrong!");
    }
  };

  return {
    chats,
    messages,
    handleChat,
    msg,
    setMsg,
    currChat,
    handleFileUpload,
    deleteChat
  };
};

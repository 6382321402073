import React, { useEffect, useState } from "react";
import { IoIosArrowDropleft } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import COLORS from "../constants/Colors";
import FormInput from "../components/FormInput";
import ActionButton from "../components/ActionButton";
import { State, City } from "country-state-city";
import { streams, yearsOfStudy, colleges } from "../constants/Strings";
import userService from "../services/userService";
import useToast from "../hooks/useToast";
import dayjs from "dayjs";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";  // Import styles

const Onboarding = () => {
  const navigate = useNavigate();
  const toast = useToast();
  const location = useLocation();
  const [states, setStates] = useState<any[]>([]);
  const [cities, setCities] = useState<any[]>([]);

  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [data, setData] = useState({
    phoneNumber: "",
    dob: "",
    gender: "",
    collegeName: "",
    state: "",
    city: "",
    stream: "",
    yearofstudy: "",
  });

  const minDate = dayjs().subtract(100, "year").toDate();
  const maxDate = dayjs().toDate();

  useEffect(() => {
    const states = State.getStatesOfCountry("IN");
    const stateData: any[] = [];
    states.map((state) => {
      stateData.push({ label: state.name, value: state.isoCode });
    });
    setStates(stateData);
  }, []);

  useEffect(() => {
    if (location.state) {
      setData(location.state);
    }
  }, [location.state]);

  useEffect(() => {
    if (data.state) {
      const cityData: any[] = [];
      const cities = City.getCitiesOfState("IN", data.state);
      cities.map((city) => {
        cityData.push({ label: city.name, value: city.name });
      });
      setCities(cityData);
    }
  }, [data.state]);

  const handlePhoneNumberChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const phone = e.target.value;
    setData({ ...data, phoneNumber: phone });

    // Validate phone number
    const phoneRegex = /^[0-9]{10}$/;
    setIsPhoneValid(phoneRegex.test(phone));
  };

  const updateUser = async () => {
    try {
      if (!data.state || !data.city || !data.stream || !data.yearofstudy) {
        toast.error("Please fill all the fields");
        return;
      }

      const res = await userService.updateUser({
        phoneNumber: data.phoneNumber,
        dob: new Date(data.dob),
        gender: data.gender,
        collegeName: data.collegeName,
        state: data.state,
        city: data.city,
        stream: data.stream,
        yearofstudy: data.yearofstudy,
      });

      toast.success("Profile updated successfully");
      if (location.state) {
        navigate("/profile");
      } else {
        navigate("/kyc");
      }
    } catch (error) {
      console.log(error, "update user error");
      toast.error("Something went wrong");
    }
  };

  return (
    <div className="flex flex-col min-h-screen my-8 mx-4">
      <div className="flex flex-row items-center gap-x-3">
        <IoIosArrowDropleft
          size={24}
          color={COLORS.purple}
          onClick={() => navigate("/")}
        />
      </div>
      <div className="my-5">
        <h4 className="font-inter text-xl">Fill the form</h4>
        <div className="flex flex-col gap-y-5 my-8">
          <FormInput
            placeholder="Mobile Number"
            onChange={handlePhoneNumberChange}
            value={data.phoneNumber}
            type="tel"
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
          />
          {!isPhoneValid && (
            <p className="text-red-500 text-xs -my-3">
              Please enter a valid 10-digit phone number.
            </p>
          )}
          <DatePicker
            selected={data.dob ? new Date(data.dob) : null}
            onChange={(date: Date | null) =>
              setData({ ...data, dob: date ? dayjs(date).format("YYYY-MM-DD") : "" })
            }
            dateFormat="yyyy-MM-dd"
            minDate={minDate}
            maxDate={maxDate}
            placeholderText="Select your birthday"
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue w-full"
          />
          {location.state && (
            <FormInput
              placeholder="Gender"
              onChange={(e) => setData({ ...data, gender: e.target.value })}
              value={data.gender}
              type="dropdown"
              options={[
                { label: "Male", value: "Male" },
                { label: "Female", value: "Female" },
              ]}
              className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
            />
          )}
          <Select
            placeholder="Select College Name"
            onChange={(option) =>
              setData({ ...data, collegeName: option?.value || "" })
            }
            value={colleges.find((college) => college.value === data.collegeName) || null}
            options={colleges}
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
          />
          <FormInput
            placeholder="State"
            onChange={(e) => setData({ ...data, state: e.target.value })}
            value={data.state}
            type="dropdown"
            options={states || []}
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
          />
          <FormInput
            placeholder="City"
            onChange={(e) => setData({ ...data, city: e.target.value })}
            value={data.city}
            type="dropdown"
            options={cities || []}
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
          />
          <FormInput
            placeholder="Stream"
            onChange={(e) => setData({ ...data, stream: e.target.value })}
            value={data.stream}
            type="dropdown"
            options={streams || []}
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
          />
          <FormInput
            placeholder="Year of study"
            onChange={(e) => setData({ ...data, yearofstudy: e.target.value })}
            value={data.yearofstudy}
            type="dropdown"
            options={yearsOfStudy || []}
            className="border-[1px] border-greyText px-2 py-2 rounded-md bg-lightBlue"
          />
          <ActionButton
            buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md"
            buttonText="Done"
            onClick={updateUser}
          />
        </div>
      </div>
    </div>
  );
};

export default Onboarding;

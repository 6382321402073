import React, { useState } from "react";
import ActionButton from "../components/ActionButton";
import Logo from "../components/Logo";
import FormInput from "../components/FormInput";
import { Link, useNavigate } from "react-router-dom";
import authService from "../services/authService";
import useAlert from "../hooks/useToast";
import Banner from '../assets/gifs/Banner.gif';

const type = process.env.REACT_APP_TYPE;
const IP = process.env.REACT_APP_IP;
const REACT_APP_PROD_SSL_SWITCH = process.env.REACT_APP_PROD_SSL_SWITCH;
const REACT_APP_PROD_SSL_PORT = process.env.REACT_APP_PROD_SSL_PORT;
const BACKEND_URL =
  type == "dev" ? "http://localhost:5000" : `${REACT_APP_PROD_SSL_SWITCH}://${IP}:${REACT_APP_PROD_SSL_PORT}`;

const Register = () => {
  const navigate = useNavigate();
  const toast = useAlert();

  const [registerData, setRegisterData] = useState({
    name: "",
    phoneNo: "",
    email: "",
    password: "",
    gender: "",
  });

  // State to toggle password visibility
  const [showPassword, setShowPassword] = useState(false);

  const handleRegisterChange = (key: string, value: string) => {
    setRegisterData({
      ...registerData,
      [key]: value,
    });
  };

  // Password strength validation
  const validatePassword = (password: string) => {

    const passwordStrengthRegex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()+=-\?;,./{}|\":<>\[\]\\\' ~_]).{8,}/

    // const passwordStrengthRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return passwordStrengthRegex.test(password);
  };

  // Phone number validation for exactly 10 digits
  const validatePhoneNumber = (phoneNo: string) => {
    const phoneNumberRegex = /^[0-9]{10}$/;
    return phoneNumberRegex.test(phoneNo);
  };

  const handleRegister = async () => {
    // Check if the name is entered
    if (!registerData.name) {
      toast.error("Please enter your name.");
      return;
    }

    // Check password strength
    if (!validatePassword(registerData.password)) {
      toast.error(
        "Password must be at least 8 characters long and include uppercase, lowercase, number, and special character."
      );
      return;
    }

    // Check phone number validity
    if (!validatePhoneNumber(registerData.phoneNo)) {
      toast.error("Please enter a valid 10-digit phone number.");
      return;
    }

    try {
      navigate(
        `/otp/${encodeURIComponent(registerData.email)}/${registerData.name}/${
          registerData.password
        }/${registerData.gender}`
      );
      // const res = await authService.register({
      //   name: registerData.name,
      //   email: registerData.email,
      //   password: registerData.password,
      //   phoneNo: registerData.phoneNo,
      //   gender: registerData.gender,
      // });
    } catch (error) {
      console.log(error, "error");
      toast.error("Something went wrong!");
    }
  };

  const googleLogin = () => {
    try {
      window.location.href = `${BACKEND_URL}/v1/auth/google`;
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <div className="flex flex-col items-center h-screen w-screen bg-cover bg-center" style={{ backgroundImage: `url(${Banner})` }}>
      <Logo className="flex-auto" />
      <div className="mt-auto flex flex-grow flex-col w-full px-6 py-8">
        <h4 className="text-white font-inter font-normal text-lg py-8 text-center -mt-16">
          Sign Up
        </h4>
        <div className="flex flex-col gap-y-5">
          <FormInput
            onChange={(e) => handleRegisterChange("name", e.target.value)}
            placeholder="Enter Name"
            type="text"
            value={registerData.name}
          />

          <FormInput
            onChange={(e) => handleRegisterChange("phoneNo", e.target.value)}
            placeholder="Enter Phone No."
            type="text"
            value={registerData.phoneNo}
          />

          <FormInput
            onChange={(e) => handleRegisterChange("email", e.target.value)}
            placeholder="Enter Email"
            type="email"
            value={registerData.email}
          />

          <div className="relative">
            <FormInput
              onChange={(e) => handleRegisterChange("password", e.target.value)}
              placeholder="Enter Password"
              type={showPassword ? "text" : "password"} // Toggle between text and password types
              value={registerData.password}
            />
            <button
              type="button"
              className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? "Hide" : "Show"}
            </button>
          </div>

          <FormInput
            onChange={(e) => handleRegisterChange("gender", e.target.value)}
            placeholder="Gender"
            type="dropdown"
            options={[
              { label: "Male", value: "Male" },
              { label: "Female", value: "Female" },
            ]}
            value={registerData.gender}
          />
        </div>
        <div className="flex flex-col gap-y-2">
          <ActionButton
            buttonClass="bg-gradient-to-br from-purple to-lightPurple rounded-md mt-4 py-2"
            buttonText="Sign Up"
            onClick={() => handleRegister()}
          />
          <p className="text-white font-inter font-normal text-sm text-center my-2">
            Have an account?{" "}
            <Link to="/login" className="text-secondary text-sm">
              Log In
            </Link>
          </p>
          <ActionButton
            buttonClass="bg-gradient-to-br from-secondary to-lightSecondary rounded-md mt-4 py-2"
            buttonText="Continue with Google"
            onClick={() => googleLogin()}
          />
        </div>
      </div>
    </div>
  );
};

export default Register;
